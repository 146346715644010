import { Drag, Drop, DropList } from 'vue-easy-dnd'
import EventBus from '@/util/EventBus'
import getEnv from '@/util/env'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import { SaveBarProperties } from '@/models/SaveBarProperties'
import { Form } from '@/classes/Form/Form.js'
import { QuestionType } from '@/classes/Form/QuestionType.js'
import FormsService from '@/services/forms.service.js'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import SaveBar from '@/components/Template/SaveBar/SaveBar.vue'
import FormsColor from './FormsColors/FormsColor.vue'
import ItemQuestion from './ItemQuestion/ItemQuestion.vue'
import CardActions from './CardActions/CardActions.vue'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import UnsuscribeService from '@/services/unsuscribe.service.js'

export default {
  name: 'FormsEditor',
  components: {
    Drag,
    DropList,
    Drop,
    HeaderTopDashboard,
    ButtonMultimedia,
    SaveBar,
    FormsColor,
    ItemQuestion,
    CardActions,
    VideoPlayer,
  },
  data: function () {
    return {
      test: false,
      ready: true,
      previewDialog: false,
      formPreviewUrl: null,
      questionType: new QuestionType(),
      typeofQuestions: [
        'text',
        'date',
        'time',
        'number',
        'textarea',
        'dropdown',
        'checkbox',
        'radio',
        'hidden',
      ],
      items: {},
      form: new Form(),
      saveForm: {
        name: '',
        json: '',
      },
      timeout: null,
      previewContent: '',
      endPoint: getEnv('VUE_APP_API_URL'),
      saveBarProperties: new SaveBarProperties(),
      renderComponent: true,
      fixedMode: false,
      absoluteMode: false,
      unsubscribePages: {
        options: [],
      },
    }
  },
  watch: {
    form: {
      handler () {
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.preview()
        }, 500)
      },
      deep: true,
    },
  },
  methods: {
    openVideoPlayer () {
      const videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 8)

      this.$store.dispatch('videoPlayer/toggle', {
        show: true,
        url: videoSms.url,
        title: videoSms.title,
      })
    },
    handleScroll (event) {
      window.onscroll = () => {
        const finishIframe = document.getElementById('finishIframe')
        const finishIframeOffsetTop = finishIframe.offsetTop
        const iframePreview = document.getElementById('topIframe')
        const iframePreviewOffsetHeight = iframePreview.offsetHeight
        const windowTopY = window.pageYOffset

        if (
          (windowTopY >= iframePreviewOffsetHeight) &&
          ((windowTopY + iframePreviewOffsetHeight) >= finishIframeOffsetTop)
        ) {
          this.fixedMode = false
          this.absoluteMode = true
        } else if (windowTopY >= iframePreviewOffsetHeight) {
          this.fixedMode = true
          this.absoluteMode = false
        } else {
          this.fixedMode = false
          this.absoluteMode = false
        }
      }
    },
    selectImage (file) {
      this.form.sections[0].items[0].imageUrl = file
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
    preview () {
      FormsService.getPreviewFromJson({ form: this.form })
      .then(
        (response) => {
          this.resetReady()
          this.previewContent = 'data:text/html;charset=utf-8,' + encodeURIComponent(response.form)
          this.resetReady()
        },
      )
    },
    newQuestion (type) {
      this.form.addQuestion(type)
      const option = this.form.sections[0].items[this.form.sections[0].items.length - 1]
      if (option.type === 'cancel-service') {
        option.unsubscribePage = this.unsubscribePages.options[0].id
      }
    },
    saveOrUpdate () {
      this.saveForm.json = JSON.stringify(this.form)
      this.saveForm.name = this.form.header.title
      FormsService.saveOrUpdate(this.saveForm)
      .then(
        (response) => {
          EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado el formulario'))
          this.saveForm.id = response.id
          this.saveBarProperties.setSavedLoader()
        },
        (error) => {
          if (error) {
            EventBus.$emit('showAlert', 'danger', this.$t('Se ha producido un error al guardar el formulario'))
          }
        },
      ).finally(() => {
        this.loading = false
      })
    },
    setInvalidElements (invalid) {
      this.saveBarProperties.invalid = invalid
    },
    changeTemplateName () {
      this.saveForm.name = this.saveBarProperties.templateName
    },
    // card-actions
    removeItem (item) {
      const index = this.form.sections[0].items.indexOf(item)
      this.form.sections[0].items.splice(index, 1)
    },
    duplicateItem (item) {
      const index = this.form.sections[0].items.lastIndexOf(item)
      this.form.sections[0].items.splice(index, 0, item)
    },
    forceRender (item) {
      const stash = item.question
      item.question = ' '
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
        item.question = stash
      })
    },
    // FormsColor
    changeColor (color) {
      this.form.properties.primaryColor = color

      switch (color) {
        case '#db4437':
          this.form.properties.backgroundColor = '#F5B7B1'
          this.form.properties.buttonTextColor = '#fff'
        break
        case '#673ab7':
          this.form.properties.backgroundColor = '#E8DAEF'
          this.form.properties.buttonTextColor = '#fff'
        break
        case '#3f51b5':
          this.form.properties.backgroundColor = '#D6EAF8'
          this.form.properties.buttonTextColor = '#fff'
        break
        case '#ff9800':
          this.form.properties.backgroundColor = '#FDEBD0'
          this.form.properties.buttonTextColor = '#000'
        break
        case '#4caf50':
          this.form.properties.backgroundColor = '#D5F5E3'
          this.form.properties.buttonTextColor = '#000'
        break
        case '#00bcd4':
          this.form.properties.backgroundColor = '#D6EAF8'
          this.form.properties.buttonTextColor = '#000'
        break
        case '#9e9e9e':
          this.form.properties.backgroundColor = '#EAEDED'
          this.form.properties.buttonTextColor = '#000'
        break
      }
    },
    // Item Question
    newBoxItem (item) {
      const index = this.form.sections[0].items.indexOf(item)
      if (this.form.sections[0].items[index].options === undefined) {
        this.form.sections[0].items[index].options = []
      }
      this.form.sections[0].items[index].options.push({
        value: '',
      })
      this.forceRender(item)
    },
    removeBoxItem (item, secondIndex) {
      const index = this.form.sections[0].items.indexOf(item)
      this.form.sections[0].items[index].options.splice(secondIndex, 1)
      this.forceRender(item)
    },
    randomizeKey () {
      return Math.floor(Math.random() * 10000) + 1
    },
  },
  created () {
    this.saveBarProperties.showPreviewButton = false
    const id = this.$route.query.id
    if (id) {
      FormsService.getFormsData(id)
        .then(
          (response) => {
            this.saveForm.id = response.form.id
            this.saveForm.name = response.form.name
            this.saveForm.json = response.form.json
            this.saveBarProperties.templateName = response.form.name
            this.form.loadForm(JSON.parse(response.form.json))
            if (this.$route.query.duplicate) {
              this.saveForm.id = null
              this.saveForm.name += ' - ' + this.$t('Copia')
              this.saveBarProperties.templateName += ' - ' + this.$t('Copia')
              this.form.header.title += ' - ' + this.$t('Copia')
            }
          },
        )
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    UnsuscribeService.getUnsubscribePages().then((res) => {
      this.unsubscribePages.options = res
    })
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
